
import {defineComponent} from 'vue'

export default defineComponent({
  data() {
    return {
      /*wHeight: "60vh",
      navigator: {
        justifyContents: {
          left: {
            float: "initial",
            justify: "center",
          },
          center: {
            float: "initial",
            justify: "center",
          },
          right: {
            float: "initial",
            justify: "center",
          },
        },
        logo: {
          maxWidth: "250px",
          marginTop: "",
        },
        heroHeadLine: {
          fontSize: "",
          marginTop: "",
        },
        heroCopy: {
          fontSize: ""
        }
      }*/
    }
  },
  computed: {
    //width() {
    //  return (window.innerWidth > 0) ? window.innerWidth : screen.width;
    //}
  },
  mounted() {
    //if (this.width < 991) {
    //  this.navigator.heroHeadLine.fontSize = "1.7rem";
    //} else {
    //  this.navigator.heroHeadLine.fontSize = "5rem";
    //}

    //window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    /*handleScroll(event: Event) {
      if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {

        if (this.width < 991) {
          this.wHeight = "0px";
          this.navigator = {
            justifyContents: {
              left: {
                float: "initial",
                justify: "center",
              },
              center: {
                float: "initial",
                justify: "center",
              },
              right: {
                float: "initial",
                justify: "center",
              },
            },
            logo: {
              maxWidth: "0px",
              marginTop: "",
            },
            heroHeadLine: {
              fontSize: "1.3rem",
              marginTop: "",
            },
            heroCopy: {
              fontSize: "1rem"
            }
          }
        } else {
          this.wHeight = "0px";
          this.navigator = {
            justifyContents: {
              left: {
                float: "left",
                justify: "flex-start",
              },
              center: {
                float: "none",
                justify: "center",
              },
              right: {
                float: "right",
                justify: "flex-end",
              },
            },
            logo: {
              maxWidth: "60px",
              marginTop: "10px",
            },
            heroHeadLine: {
              fontSize: "1.3rem",
              marginTop: "28px",
            },
            heroCopy: {
              fontSize: "1rem"
            }
          }
        }
      } else {

        if (this.width < 991) {
          this.wHeight = "60vh";
          this.navigator = {
            justifyContents: {
              left: {
                float: "initial",
                justify: "center",
              },
              center: {
                float: "initial",
                justify: "center",
              },
              right: {
                float: "initial",
                justify: "center",
              },
            },
            logo: {
              maxWidth: "250px",
              marginTop: "",
            },
            heroHeadLine: {
              fontSize: "1.7rem",
              marginTop: "",
            },
            heroCopy: {
              fontSize: "1.3rem"
            }
          }
        } else {
          this.wHeight = "60vh";

          this.navigator = {
            justifyContents: {
              left: {
                float: "initial",
                justify: "center",
              },
              center: {
                float: "initial",
                justify: "center",
              },
              right: {
                float: "initial",
                justify: "center",
              },
            },
            logo: {
              maxWidth: "250px",
              marginTop: "",
            },
            heroHeadLine: {
              fontSize: "5rem",
              marginTop: "",
            },
            heroCopy: {
              fontSize: "2rem"
            }
          }
        }


      }
    }*/
  }
})


