import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_Resume = _resolveComponent("Resume")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TheHeader),
    _createElementVNode("main", null, [
      _createVNode(_component_Resume)
    ])
  ]))
}